import React, { useEffect, useState } from "react";

import "./index.css";

import { Table } from "react-bootstrap";
import { getTeamContribution, getTeamData, getTeamUsers } from "../../Api/Api";
import { roundToFourDecimals} from "../../Common";
import { IoMdArrowBack } from "react-icons/io";
import { useNavigate } from "react-router-dom";
import { Col, Row } from "reactstrap";

const TeamContribution = () => {
  const [teamData, setTeamata] = useState();
  const [teamUsers, setTeamUsers] = useState();
  const [teamContribution, setTeamContribution] = useState();
  const navigate = useNavigate();
  useEffect(() => {
    const fetchTeamInfo = async () => {
      try {
        const response = await getTeamData();
        setTeamata(response?.data?.data);
      } catch (error) {
        console.error("Error fetching team:", error);
      }
    };
    const fetchTeamUsers = async () => {
      try {
        const response = await getTeamUsers();
        setTeamUsers(response?.data?.data);
      } catch (error) {
        console.error("Error fetching team:", error);
      }
    };
    const fetchTeamContribution = async () => {
      try {
        const response = await getTeamContribution();
        setTeamContribution(response?.data?.data);
      } catch (error) {
        console.error("Error fetching team:", error);
      }
    };

    fetchTeamInfo();
    fetchTeamUsers();
    fetchTeamContribution();
  }, []);
  return (
    <div className="main-div">
      <br />
      <span className="back-icon" onClick={() => navigate("/Home")}>
        <IoMdArrowBack />
      </span>
      <div className="ml-3">
        <Row className="w-100 no-gutters justify-content-center mt-5">
          <Col xl={12} sm={12} md={12}>
            <div className="d-flex justify-content-center">
              <img className="logo mt-3" src="/logo.png" alt="Logo" />
            </div>
            <h2 className="text-center text-white mt-2"> Team Contribution</h2>
            <p className="login-text mt-2 text-center">
              Check Your Team Income and Contribution!
            </p>
            <hr />

            <div className="mt-5 mb-5">
              <div className="row">
                <div className="col-6">
                  <h5>
                    Total People: <span> {teamData?.totalPeople}</span>
                  </h5>
                </div>

                <div className="col-6 text-right">
                  <h5>
                    New Users: <span> {teamData?.newRegistrations} </span>
                  </h5>
                </div>

                <div className="col-6 mt-3">
                  <h5>
                    {" "}
                    Recharge Today:{" "}
                    <span>
                      {" "}
                      ${roundToFourDecimals(teamData?.addedRecharge)}
                    </span>
                  </h5>
                </div>
              </div>
            </div>
            <hr />
            <h6 className="mt-1" style={{ color: "grey" }}>
              Note: Users who have used your refereal, their information and
              your profit will be listed down below.
            </h6>
            <Table striped bordered hover responsive className="mt-3">
              <thead>
                <tr>
                  <th>#</th>
                  <th> Account </th>
                  <th> Hirarchy </th>
                  <th> Total Profit Today</th>
                  <th> Total Profit Yesterday</th>
                  <th> Total Profit Last Week</th>
                </tr>
              </thead>
              <tbody>
                {teamContribution?.map((data, index) => (
                  <tr key={index}>
                    <td>{index + 1}</td>
                    <td>{data?.email}</td>
                    <td>{data?.type}</td>
                    <td>${roundToFourDecimals(data?.today)}</td>
                    <td>${roundToFourDecimals(data?.yesterday)}</td>
                    <td>${roundToFourDecimals(data?.lastWeek)}</td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default TeamContribution;
