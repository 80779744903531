import { initializeApp } from "firebase/app";
import { getStorage } from "firebase/storage";

const firebaseConfig = {
  aapiKey: "AIzaSyAcHpbLVWy7XNUdjU-uJBsagTYQ9hIr9Zg",
  authDomain: "crypto-trade5.firebaseapp.com",
  projectId: "crypto-trade5",
  storageBucket: "crypto-trade5.appspot.com",
  messagingSenderId: "554233191179",
  appId: "1:554233191179:web:bb1ceb850c3c9c9a0a200e",
  measurementId: "G-F3Q1L9BP99"
};


const app = initializeApp(firebaseConfig);


const storage = getStorage(app);

export default storage;