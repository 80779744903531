import React, { useState } from "react";

import "./Home.css";

import HomeCards from "./Home.Cards";

import CustomNavbar from "../../Components/Navbar/Navbar";
import BottomBar from "../../Components/BottomBar/BottomBar";

const Home = () => {
  return (
    <>
      <CustomNavbar />
      <div className="main-div">
        <HomeCards />

        <BottomBar />
      </div>
    </>
  );
};

export default Home;
